*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 375px) {
  .container {
    max-width: 375px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 769px) {
  .container {
    max-width: 769px;
  }
}

@media (width >= 1025px) {
  .container {
    max-width: 1025px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (width >= 1800px) {
  .container {
    max-width: 1800px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.\!inset-0 {
  inset: 0 !important;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[-20\%\] {
  bottom: -20%;
}

.left-0 {
  left: 0;
}

.left-\[28px\] {
  left: 28px;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-\[24px\] {
  right: 24px;
}

.top-0 {
  top: 0;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[35\%\] {
  top: 35%;
}

.top-\[50\%\] {
  top: 50%;
}

.\!z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[1\] {
  z-index: 1;
}

.order-1 {
  order: 1;
}

.col-span-full {
  grid-column: 1 / -1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-\[8px\] {
  margin-top: 8px;
  margin-bottom: 8px;
}

.\!mb-\[15px\] {
  margin-bottom: 15px !important;
}

.\!mb-\[24px\] {
  margin-bottom: 24px !important;
}

.\!ml-0 {
  margin-left: 0 !important;
}

.\!ml-2 {
  margin-left: .5rem !important;
}

.\!ml-\[-8px\] {
  margin-left: -8px !important;
}

.\!mr-\[2px\] {
  margin-right: 2px !important;
}

.\!mt-0, .\!mt-\[0\] {
  margin-top: 0 !important;
}

.\!mt-\[20px\] {
  margin-top: 20px !important;
}

.\!mt-\[4px\] {
  margin-top: 4px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-\[-5px\] {
  margin-bottom: -5px;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[11px\] {
  margin-bottom: 11px;
}

.mb-\[14px\] {
  margin-bottom: 14px;
}

.mb-\[15px\] {
  margin-bottom: 15px;
}

.mb-\[16px\] {
  margin-bottom: 16px;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[4px\] {
  margin-bottom: 4px;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-\[10px\] {
  margin-right: 10px;
}

.mr-\[8px\] {
  margin-right: 8px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-\[-5px\] {
  margin-top: -5px;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[14px\] {
  margin-top: 14px;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[16px\] {
  margin-top: 16px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[22px\] {
  margin-top: 22px;
}

.mt-\[24px\] {
  margin-top: 24px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!h-auto {
  height: auto !important;
}

.h-0 {
  height: 0;
}

.h-\[112px\] {
  height: 112px;
}

.h-\[120\%\] {
  height: 120%;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[21px\] {
  height: 21px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[86px\] {
  height: 86px;
}

.h-\[88px\] {
  height: 88px;
}

.h-\[96px\] {
  height: 96px;
}

.h-full {
  height: 100%;
}

.max-h-\[456px\] {
  max-height: 456px;
}

.\!w-\[16px\] {
  width: 16px !important;
}

.\!w-\[17px\] {
  width: 17px !important;
}

.\!w-\[19px\] {
  width: 19px !important;
}

.\!w-\[20px\] {
  width: 20px !important;
}

.\!w-\[24px\] {
  width: 24px !important;
}

.\!w-\[27px\] {
  width: 27px !important;
}

.\!w-auto {
  width: auto !important;
}

.\!w-full {
  width: 100% !important;
}

.w-0 {
  width: 0;
}

.w-\[104vw\] {
  width: 104vw;
}

.w-\[112px\] {
  width: 112px;
}

.w-\[19px\] {
  width: 19px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[72px\] {
  width: 72px;
}

.w-\[86px\] {
  width: 86px;
}

.w-\[88px\] {
  width: 88px;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[94px\] {
  width: 94px;
}

.w-\[96px\] {
  width: 96px;
}

.w-full {
  width: 100%;
}

.\!min-w-\[135px\] {
  min-width: 135px !important;
}

.\!min-w-\[unset\] {
  min-width: unset !important;
}

.\!max-w-\[unset\] {
  max-width: unset !important;
}

.\!max-w-full {
  max-width: 100% !important;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-\[184px\] {
  max-width: 184px;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.max-w-\[460px\] {
  max-width: 460px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-full {
  max-width: 100%;
}

.\!flex-\[unset\] {
  flex: unset !important;
}

.flex-1 {
  flex: 1;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.\!basis-\[120px\] {
  flex-basis: 120px !important;
}

.\!basis-\[140px\] {
  flex-basis: 140px !important;
}

.\!basis-\[150px\] {
  flex-basis: 150px !important;
}

.\!basis-\[160px\] {
  flex-basis: 160px !important;
}

.\!basis-\[200px\] {
  flex-basis: 200px !important;
}

.\!basis-\[20px\] {
  flex-basis: 20px !important;
}

.\!basis-\[50px\] {
  flex-basis: 50px !important;
}

.border-collapse {
  border-collapse: collapse;
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-54\%\] {
  --tw-translate-x: -54%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-1px\] {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-47\%\] {
  --tw-translate-y: -47%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!gap-0 {
  gap: 0 !important;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[12px\] {
  gap: 12px;
}

.gap-\[13px\] {
  gap: 13px;
}

.gap-\[15px\] {
  gap: 15px;
}

.gap-\[16px\] {
  gap: 16px;
}

.gap-\[17px\] {
  gap: 17px;
}

.gap-\[20px\] {
  gap: 20px;
}

.gap-\[24px\] {
  gap: 24px;
}

.gap-\[25px\] {
  gap: 25px;
}

.gap-\[28px\] {
  gap: 28px;
}

.gap-\[30px\] {
  gap: 30px;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[6px\] {
  gap: 6px;
}

.gap-\[8px\] {
  gap: 8px;
}

.gap-\[9px\] {
  gap: 9px;
}

.gap-x-\[20px\] {
  column-gap: 20px;
}

.gap-x-\[30px\] {
  column-gap: 30px;
}

.gap-y-\[10px\] {
  row-gap: 10px;
}

.gap-y-\[15px\] {
  row-gap: 15px;
}

.gap-y-\[16px\] {
  row-gap: 16px;
}

.self-center {
  align-self: center;
}

.\!overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-\[12px\] {
  border-radius: 12px;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-b-\[20px\] {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.border, .border-\[1px\] {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#E6E6E6\] {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity, 1));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-left-bottom {
  object-position: left bottom;
}

.p-0 {
  padding: 0;
}

.p-\[5px\] {
  padding: 5px;
}

.p-\[6px\] {
  padding: 6px;
}

.\!px-\[0px\] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\!py-\[10px\] {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.\!py-\[13px\] {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.\!py-\[14px\] {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.\!py-\[16px\] {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.\!pl-\[12px\] {
  padding-left: 12px !important;
}

.\!pl-\[15px\] {
  padding-left: 15px !important;
}

.\!pl-\[16px\] {
  padding-left: 16px !important;
}

.\!pr-\[10px\] {
  padding-right: 10px !important;
}

.\!pr-\[14px\] {
  padding-right: 14px !important;
}

.\!pr-\[18px\] {
  padding-right: 18px !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-\[28px\] {
  padding-bottom: 28px;
}

.pb-\[31px\] {
  padding-bottom: 31px;
}

.pl-0 {
  padding-left: 0;
}

.pt-\[14px\] {
  padding-top: 14px;
}

.pt-\[20px\] {
  padding-top: 20px;
}

.pt-\[24px\] {
  padding-top: 24px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.text-left {
  text-align: left;
}

.\!text-center {
  text-align: center !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.\!opacity-0 {
  opacity: 0 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (width >= 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:shrink-0 {
    flex-shrink: 0;
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:gap-\[20px\] {
    gap: 20px;
  }

  .sm\:text-left {
    text-align: left;
  }
}

@media (width >= 769px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:left-\[50\%\] {
    left: 50%;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-\[50\%\] {
    top: 50%;
  }

  .md\:z-\[2\] {
    z-index: 2;
  }

  .md\:order-\[0\], .md\:order-none {
    order: 0;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:\!mb-\[21px\] {
    margin-bottom: 21px !important;
  }

  .md\:mb-\[16px\] {
    margin-bottom: 16px;
  }

  .md\:mb-\[30px\] {
    margin-bottom: 30px;
  }

  .md\:mb-\[40px\] {
    margin-bottom: 40px;
  }

  .md\:ml-\[10px\] {
    margin-left: 10px;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-\[-12px\] {
    margin-top: -12px;
  }

  .md\:mt-\[14px\] {
    margin-top: 14px;
  }

  .md\:mt-\[16px\] {
    margin-top: 16px;
  }

  .md\:mt-\[20px\] {
    margin-top: 20px;
  }

  .md\:mt-\[21px\] {
    margin-top: 21px;
  }

  .md\:mt-\[24px\] {
    margin-top: 24px;
  }

  .md\:mt-\[26px\] {
    margin-top: 26px;
  }

  .md\:mt-\[32px\] {
    margin-top: 32px;
  }

  .md\:mt-\[3px\] {
    margin-top: 3px;
  }

  .md\:mt-\[4px\] {
    margin-top: 4px;
  }

  .md\:hidden {
    display: none;
  }

  .md\:min-h-\[357px\] {
    min-height: 357px;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-\[202px\] {
    max-width: 202px;
  }

  .md\:max-w-\[596px\] {
    max-width: 596px;
  }

  .md\:max-w-\[630px\] {
    max-width: 630px;
  }

  .md\:shrink-0 {
    flex-shrink: 0;
  }

  .md\:\!basis-\[395px\] {
    flex-basis: 395px !important;
  }

  .md\:translate-y-\[calc\(-50\%-5px\)\] {
    --tw-translate-y: calc(-50% - 5px);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-\[12px\] {
    gap: 12px;
  }

  .md\:gap-\[16px\] {
    gap: 16px;
  }

  .md\:gap-\[24px\] {
    gap: 24px;
  }

  .md\:gap-\[28px\] {
    gap: 28px;
  }

  .md\:gap-\[36px\] {
    gap: 36px;
  }

  .md\:gap-x-\[28px\] {
    column-gap: 28px;
  }

  .md\:gap-y-\[21px\] {
    row-gap: 21px;
  }

  .md\:gap-y-\[68px\] {
    row-gap: 68px;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:rounded-\[40px\] {
    border-radius: 40px;
  }

  .md\:px-\[24px\] {
    padding-left: 24px;
    padding-right: 24px;
  }

  .md\:px-\[72px\] {
    padding-left: 72px;
    padding-right: 72px;
  }

  .md\:pt-\[26px\] {
    padding-top: 26px;
  }
}

@media (width >= 1025px) {
  .lg\:sticky {
    position: sticky;
  }

  .lg\:top-\[100px\] {
    top: 100px;
  }

  .lg\:\!mt-\[40px\] {
    margin-top: 40px !important;
  }

  .lg\:\!mt-\[42px\] {
    margin-top: 42px !important;
  }

  .lg\:mb-\[15px\] {
    margin-bottom: 15px;
  }

  .lg\:mb-\[47px\] {
    margin-bottom: 47px;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mt-\[16px\] {
    margin-top: 16px;
  }

  .lg\:mt-\[40px\] {
    margin-top: 40px;
  }

  .lg\:mt-\[44px\] {
    margin-top: 44px;
  }

  .lg\:mt-\[64px\] {
    margin-top: 64px;
  }

  .lg\:max-w-\[100vw\] {
    max-width: 100vw;
  }

  .lg\:max-w-\[300px\] {
    max-width: 300px;
  }

  .lg\:shrink-0 {
    flex-shrink: 0;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-\[30px\] {
    gap: 30px;
  }

  .lg\:gap-\[56px\] {
    gap: 56px;
  }

  .lg\:\!overflow-visible {
    overflow: visible !important;
  }

  .lg\:pt-\[64px\] {
    padding-top: 64px;
  }
}
/*# sourceMappingURL=styles.dc82d617.css.map */
